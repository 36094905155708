<template>
 <div>
  <Navigation/>
<FAQ/>
<Partners/>
<Footer/>
 </div>
</template>

<script>

import Navigation from "../components/Navigation.vue";
import Partners from "../components/Partners.vue";
import Footer from "../components/Footer.vue";
import FAQ from "../components/FAQ.vue";
 export default {
    components: { Navigation, Partners, Footer, FAQ }
}
</script>

<style lang="sass" scoped>

</style>