<template>
 <div id="faq">
  <div class="headerContacts">
   <b-container>
    <b-row>
     <b-col class="container-title" lg="6" md="12">
      <p>Read more details in our free website knowledge base,Common FAQ</p>
      <h2>GENERAL QUESTIONS</h2>
     </b-col>
     <b-col class="container-img" lg="6" md="12"
      ><img src="../assets/contact.png" alt="about"
     /></b-col>
    </b-row>
   </b-container>
  </div>
  <div class="General-Question">
   <b-container>
    <b-row>
     <b-col>
      <div class="main-title">
       <p>Common FAQs</p>
       <h2>GENERAL QUESTIONS</h2>
      </div>
      <div class="bodyFAQ">
       <div class="the-question">
        <div class="question">
         <span>What form of payments do you accept? </span
         ><span v-on:click="FAQTap = '1'"><i class="fas fa-plus"></i></span>
        </div>
        <div v-if="FAQTap === '1'" class="the-answer">
         <p>
          <strong>A:</strong> We currently accept taduwel and all major credit
          card payments, as well as International Wire transfers for invoices
          larger than $500.
         </p>
         <p><strong>B:</strong> We Love Bitcoins.</p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>Can I change my payment method? </span
         ><span v-on:click="FAQTap = '2'"
          ><i :class="FAQTap === '2' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '2'" class="the-answer">
         <p>
          <strong>A:</strong> You can choose your method of payment for each
          invoice.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>What is the delivery speed of dedicated servers? </span
         ><span v-on:click="FAQTap = '3'"
          ><i :class="FAQTap === '3' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '3'" class="the-answer">
         <p>
          <strong>A:</strong> Dedicated servers are usually delivered between 1
          to 3 business days from payment confirmation*. Some locations may take
          a little longer, please contact us for more information on the
          subject.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>How can I change the nameservers for my domain? </span
         ><span v-on:click="FAQTap = '4'"
          ><i :class="FAQTap === '4' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '4'" class="the-answer">
         <p>
          <strong>A:</strong> We verify and provision each order manually.
          Usually, it takes less an hour.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>Do you What counts as payment confirmation?</span
         ><span v-on:click="FAQTap = '5'"
          ><i :class="FAQTap === '5' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '5'" class="the-answer">
         <p>
          <strong>A:</strong> All new customers must go through an initial,
          one-time verification process. Payments are only confirmed once this
          verification has been completed successfully.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>How do I order additional IPs?</span
         ><span v-on:click="FAQTap = '6'"
          ><i :class="FAQTap === '6' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '6'" class="the-answer">
         <p>
          <strong>A:</strong> You may order additional IPs in the IP Manage tab
          of your server’s Manage page, however, this option only exists in
          certain locations. If the option is not present, simply make a request
          through our support system and our staff will assist you promptly.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>How much do additional IPs cost?</span
         ><span v-on:click="FAQTap = '7'"
          ><i :class="FAQTap === '7' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '7'" class="the-answer">
         <p>
          <strong>A:</strong> The cost of additional IPs varies greatly from
          location to location, please contact our sales team for more
          information.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>Can I order more RAM or storage for my server?</span
         ><span v-on:click="FAQTap = '8'"
          ><i :class="FAQTap === '8' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '8'" class="the-answer">
         <p>
          <strong>A:</strong> This option is not available in all of our
          locations. Please contact our sales team for more information on this
          matter.
         </p>
        </div>
       </div>
       <div class="the-question">
        <div class="question">
         <span>Do you offer managed services?</span
         ><span v-on:click="FAQTap = '9'"
          ><i :class="FAQTap === '9' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '9'" class="the-answer">
         <p>
          <strong>A:</strong> We are only offering unmanaged services on both
          our dedicated and virtual servers unless specified in the addons.
          Therefore, you are responsible to backup any important data on your
          server and are fully responsible regarding any configuration or
          changes made on the server.
         </p>
        </div>
       </div>
              <div class="the-question">
        <div class="question">
         <span>How long does it take to be verified?</span
         ><span v-on:click="FAQTap = '10'"
          ><i :class="FAQTap === '10' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '10'" class="the-answer">
         <p>
          <strong>A:</strong> Account verifications are generally within one business day of an order being completed.
         </p>
        </div>
       </div>
                     <div class="the-question">
        <div class="question">
         <span>How does the verification system work?</span
         ><span v-on:click="FAQTap = '11'"
          ><i :class="FAQTap === '11' ? 'fas fa-minus ' : 'fas fa-plus'"></i
         ></span>
        </div>
        <div v-if="FAQTap === '11'" class="the-answer">
         <p>
          <strong>A:</strong> The verification system was implemented in order to avoid fraudulent actions or illegal transactions. New customers paying with a credit card or an unverified PayPal account will be required to provide identification. Verified PayPal account will not be required to do this verification. Please note that verification will be required again if you decide to change your payment method.
         </p>
        </div>
       </div>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="contact-forms">
   <b-container>
    <div class="main-title">
     <p>Have question?</p>
     <h2>SEND A MESSAGE</h2>
    </div>
    <b-row>
     <b-col xl="7" lg="7" md="12">
      <b-form class="container-form">
       <b-form-group>
        <b-form-input
         type="text"
         placeholder="Name *"
         class="input-contacts"
        ></b-form-input>
        <b-form-input
         type="email"
         placeholder="Email *"
         class="input-contacts"
        ></b-form-input>
        <b-form-input
         type="text"
         placeholder="Subject *"
         class="input-contacts"
        ></b-form-input>
        <b-form-textarea
         class="input-contacts"
         placeholder="Message *"
        ></b-form-textarea>
       </b-form-group>
       <button type="submit">
        <span><i class="fa fa-envelope"></i></span> Send your Message
       </button>
      </b-form>
     </b-col>
     <b-col xl="5" lg="5" md="12">
      <img src="../assets/contact-page-banner.png" alt="" />
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="container-map-gps">
   <b-container>
    <b-row>
     <b-col>
      <div class="mapSaharaCloudCompany">
       <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26821.436310050125!2d13.04663!3d32.827255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70fe1ce400b80cec!2z2LnYp9io2LHYqSDYp9mE2LXYrdix2KfYoSDZiCDYp9mE2YbYrtmE2KrZitmG!5e0!3m2!1sen!2sus!4v1639267319044!5m2!1sen!2sus"
        width="100%"
        height="350"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
       ></iframe>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
 </div>
</template>

<script>
export default {
 name: "faq",
 data() {
  return {
   FAQTap: "0",
  };
 },
 methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#faq {
 .headerContacts {
  position: relative;
  padding-top: 70px;
  padding-bottom: 80px;
  background-color: #0e0b2b;
  .container-title {
   padding-top: 81px;
   h2 {
    padding: 0 0 30px 0;
    font-weight: 800;
    font-size: 40px;
    color: #008aff;
    font-family: "Montserrat", sans-serif !important;
    letter-spacing: 1px;
   }
   p {
    margin-top: 54px;
    font-size: 17px;
    line-height: 26px;
    font-family: "Montserrat", sans-serif !important;
    transition: 0.2s linear;
    color: blanchedalmond;
   }
  }
  .container-img {
   padding-left: 25px;
   img {
    width: 95%;
   }
  }
 }
 .General-Question {
  .main-title {
   padding-bottom: 60px;
   p {
    font-size: 18px;
    color: #0d6efd;
    transition: 0.2s linear;
    font-family: "Montserrat", sans-serif !important;
    margin-bottom: 0;
    text-align: center;
   }
   h2 {
    font-size: 36px;
    font-weight: 700;
    font-family: "Overpass", sans-serif;
    color: #1c2746;
    text-align: center;
   }
  }
  .bodyFAQ {
   padding-bottom: 44px;
   .the-question {
    padding: 6px 6px;
    margin: 7px 0;

    .question {
     display: flex;
     justify-content: space-between;
     span {
      font-size: 18px;
      font-family: $Paragraph-font;
      color: map-get($branding, color-text-numbers);
      font-weight: 500;
      &:hover {
       color: map-get($branding, theme-color-main-magenta);
      }
      .fa-plus {
       color: map-get($branding, theme-color-main-magenta);
       cursor: pointer;
      }
     }
    }
    .the-answer {
     padding: 5px 0;
     p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-family: $Paragraph-font;
      color: #777;
     }
    }
   }
  }
 }
 .contact-forms {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #0e0b2b;
  .main-title {
   padding-bottom: 60px;
   p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.85);
    transition: 0.2s linear;
    font-family: "Montserrat", sans-serif !important;
    margin-bottom: 0;
    text-align: center;
   }
   h2 {
    font-size: 36px;
    font-weight: 700;
    font-family: "Overpass", sans-serif;
    color: #ecf0fc;
    text-align: center;
   }
  }
  .container-form {
   .input-contacts {
    color: blanchedalmond;
    width: 100%;
    font-size: 13px;
    background-color: #1c2746;
    border-radius: 5px;
    box-shadow: inset 0 0 0;
    height: 47px;
    transition: 0.3s linear;
    margin: 10px 0;
    box-shadow: inset 0 0 0 #fff;
    border: 0;
    border-color: #fff;
   }
   button {
    box-shadow: inset 0 0 0 #fff;
    margin: 15px 0;
    background-color: #8d3de2;
    color: blanchedalmond;
    font-size: 16px;
    padding: 14px 42px 14px;
    transition: 0.1s linear;
    border-radius: 3px;
    border: none;
    font-family: "Montserrat", sans-serif !important;
    span {
     margin-right: 3px;
     .fa-envelope {
      font-size: 16px;
      text-align: center;
      color: blanchedalmond;
     }
    }
   }
   button:hover {
    background-color: #5303a9;
   }
  }
  img {
   width: 100%;
  }
 }
 .container-map-gps {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ffff;
  .mapSaharaCloudCompany {
   iframe {
    border-radius: 15px;
   }
  }
 }
 .container-map-gps::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #0f0e2b;
  clip-path: polygon(100% 69%, 0 100%, 100% 100%);
 }

 .headerContacts::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  clip-path: polygon(100% 69%, 0 102%, 103% 100%);
 }
}
</style>
